import { useState, useEffect, useRef } from "react";
import { Input, useToast } from "@chakra-ui/react";
import "./MeetingsParticipantsSection.css";

const MeetingsParticipantsSection = ({ renderingData, onChange }) => {
  const toast = useToast();
  const [isUnmounting, setIsUnmounted] = useState(false);
  const isUnmountingRef = useRef(isUnmounting);
  const [invalidFields, setInvalidFields] = useState({
    max_participants: false,
    max_meeting_time: false,
  });

  useEffect(() => {
    return () => {
      setIsUnmounted(!isUnmounting);
      isUnmountingRef.current = true;
      toast.closeAll();
    };
  });

  const handleChange = (e, fieldName) => {
    const value = e.target.valueAsNumber;

    onChange(
      {
        ...renderingData,
        [fieldName]: Number(value) || "",
      },
      fieldName,
      getIsValid(value, fieldName)
    );
  };

  const getIsValid = (value, fieldName) => {
    if (fieldName === "max_participants") {
      return value === "" || (Number(value) > 1 && /^[0-9]*$/.test(value));
    } else {
      return value === "" || (Number(value) > 0 && /^[0-9]*$/.test(value));
    }
  };

  const validateChange = async (e, fieldName) => {
    await new Promise((r) => setTimeout(r, 200));
    if (isUnmountingRef.current) {
      e.preventDefault();
      return;
    }
    const value = e.target.valueAsNumber;

    let isValid = getIsValid(value, fieldName);
    let toastMsg = "";

    if (fieldName === "max_participants") {
      toastMsg = "Max paricipants value must be greater than or equal to 2";
    } else {
      toastMsg = "Please enter a valid positive number.";
    }

    if (!isValid) {
      toast({
        title: "Invalid value",
        description: toastMsg,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    onChange(
      {
        ...renderingData,
        [fieldName]: Number(value) || "",
      },
      fieldName,
      isValid
    );
    setInvalidFields({ ...invalidFields, [fieldName]: !isValid });
    return isValid;
  };
  return (
    <div className="meetings-participants ">
      <b className="title54">Meeting and Participants</b>
      <div className="optionframes">
        <div className="max-meeting-time">
          <div className="text-and-switch">
            <div className="max-meeting-time1">
              <div className="max-meeting-time-container">
                <span className="span">Max Meeting Time</span>
                <span className="mins"> (Mins)</span>
              </div>
            </div>
          </div>
          <Input
            className="dropdown1"
            placeholder="Enter time"
            width="160px"
            w="160px"
            type="number"
            isInvalid={invalidFields.max_meeting_time}
            errorBorderColor="red.300"
            value={renderingData.max_meeting_time}
            onChange={(data) => handleChange(data, "max_meeting_time")}
            onBlur={(data) => validateChange(data, "max_meeting_time")}
          />
        </div>
        <div className="number-of-participants">
          <div className="text-and-switch">
            <div className="max-meeting-time1">
              <div className="max-meeting-time4">Max Participants</div>
            </div>
          </div>
          <div className="fieldandinfo">
            <Input
              className="dropdown2"
              placeholder="Enter number"
              width="160px"
              w="160px"
              type="number"
              errorBorderColor="red.300"
              isInvalid={invalidFields.max_participants}
              value={renderingData.max_participants}
              onChange={(data) => handleChange(data, "max_participants")}
              onBlur={(data) => validateChange(data, "max_participants")}
            />
            <div className="hovericon">
              <img className="info-icon" alt="" src="/info.svg" />
              <div className="tooltip">
                <div className="setup-must-be">
                  Setup must be adequate to handle this load
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetingsParticipantsSection;
