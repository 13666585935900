//Libraries
import { useState, useEffect, useRef } from "react";
import { Input, useToast } from "@chakra-ui/react";

//Components
import LabelAndCustomSelect from "../../../CommonComponents/CustomInputWithLabel/LabelAndCustomSelect";

//Strings
import { STRINGS } from "../../../Utility/StringsEn";

//Styles
import "./PerformanceTuningSection.css";

//Constants
import {
  PREFERRED_CODEC_OPTIONS,
  QUALITY_OPTIONS,
} from "../../../Constants/AdminSettingsConstants";

const PerformanceTuningSection = ({ renderingData, onChange }) => {
  const [isUnmounting, setIsUnmounting] = useState(false);
  const isUnmountingRef = useRef(isUnmounting);
  const toast = useToast();
  const [invalidFields, setInvalidFields] = useState({
    active_numberof_streams: false,
  });

  useEffect(() => {
    return () => {
      setIsUnmounting(!isUnmounting);
      isUnmountingRef.current = true;
      toast.closeAll();
    };
  });

  const handleChange = (e, fieldName) => {
    const value = e.target.valueAsNumber;
    const isValid =
      value === "" || (Number(value) >= -1 && Number(value) !== 0);

    onChange(
      {
        ...renderingData,
        [fieldName]: Number(value) || "",
      },
      fieldName,
      isValid
    );
  };

  const validateChange = async (e, fieldName) => {
    await new Promise((r) => setTimeout(r, 200));
    if (isUnmountingRef.current) {
      e.preventDefault();
      return;
    }
    const value = e.target.valueAsNumber;
    const isValid =
      value === "" || (Number(value) >= -1 && Number(value) !== 0);

    if (!isValid) {
      toast({
        title: "Invalid value",
        description: `Active video streams must be a valid positive number or -1.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setInvalidFields({ ...invalidFields, [fieldName]: !isValid });
    onChange(
      {
        ...renderingData,
        [fieldName]: Number(value) || "",
      },
      fieldName,
      isValid
    );
  };

  return (
    <div className="performance-tuning">
      <b className="title57">{STRINGS.videoQuality}</b>
      <div className="optionframes1">
        <LabelAndCustomSelect
          label={STRINGS.bigTileQuality}
          renderingOptions={QUALITY_OPTIONS}
          fieldName={"big_tile_quality"}
          onChange={(data) => {
            onChange({
              ...renderingData,
              big_tile_quality: data.target.value,
            });
          }}
          value={renderingData.big_tile_quality}
        />
        <LabelAndCustomSelect
          label={STRINGS.smallTileQuality}
          renderingOptions={QUALITY_OPTIONS}
          fieldName={"small_tile_quality"}
          onChange={(data) => {
            onChange({
              ...renderingData,
              small_tile_quality: data.target.value,
            });
          }}
          value={renderingData.small_tile_quality}
        />
        <LabelAndCustomSelect
          label={STRINGS.thumbnailTileQuality}
          renderingOptions={QUALITY_OPTIONS}
          fieldName={"thumbnail_tile_quality"}
          onChange={(data) => {
            onChange({
              ...renderingData,
              video_resolution: data.target.value,
            });
          }}
          value={renderingData.thumbnail_tile_quality}
        />

        <LabelAndCustomSelect
          label={STRINGS.screenSharingQuality}
          renderingOptions={QUALITY_OPTIONS}
          fieldName={"screen_sharing_quality"}
          onChange={(data) => {
            onChange({
              ...renderingData,
              screen_sharing_quality: data.target.value,
            });
          }}
          value={renderingData.screen_sharing_quality}
        />
        <LabelAndCustomSelect
          label={STRINGS.preferredCodec}
          renderingOptions={PREFERRED_CODEC_OPTIONS}
          fieldName={"codec"}
          onChange={(data) => {
            onChange({
              ...renderingData,
              codec: data.target.value,
            });
          }}
          value={renderingData.codec}
        />
        <div className="max-meeting-time11">
          <div className="text-and-switch6">
            <div className="video-resolution1">
              <div className="max-meeting-time10">Active Video Streams</div>
            </div>
          </div>
          <div className="fieldandinfo">
            <Input
              className="dropdown6"
              placeholder="Enter number"
              width="160px"
              w="160px"
              type="number"
              isInvalid={invalidFields.active_numberof_streams}
              errorBorderColor="red.300"
              value={renderingData.active_numberof_streams}
              onChange={(data) => handleChange(data, "active_numberof_streams")}
              onBlur={(data) => validateChange(data, "active_numberof_streams")}
            />
            <div className="hovericon">
              <img className="info-icon" alt="" src="/info.svg" />
              <div className="tooltip">
                <div className="setup-must-be">
                  {STRINGS.activeVideoStreamsTooltip}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceTuningSection;
