import FeatureCard from "../FeatureCard/FeatureCard";
import "./FeaturesContainer.css";

const FeaturesContainer = ({
  title,
  featureList = [],
  dependentFeatures = [],
  renderingData,
  onChange,
  dependentOn,
  isTranscriptOff
}) => {
  return (
    <div className="features">
      <b className="title">{title}</b>
      <div className="f-inner">
        {featureList.map((featureCard, index) => {

          let isEnabled =
            dependentOn || !dependentFeatures.includes(featureCard.name);

          return (
            <FeatureCard
              key={index}
              icon={featureCard.icon}
              label={featureCard.label}
              isChecked={renderingData?.[featureCard.name]}
              isDisabled={featureCard?.name==="call_summary"?!isTranscriptOff:(!featureCard.isEnabled || !isEnabled) }
              name={featureCard.name}
              description={featureCard.description}
              onChange={(event) => {
                onChange({ [event.target.name]: event.target.checked });
              }}
              renderingData={renderingData}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FeaturesContainer;
